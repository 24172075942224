<template>
  <div class="lesson__container">
    <div class="d-flex flex-row justify-content-between my-3 px-2">
      <div class="d-flex flex-row align-items-baseline">
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold ft-s-18">รายละเอียดบทเรียน</h4>
        <!-- <small class="text-black-50 ml-2 font-weight-bold"
          >จำนวน
          {{ formLessons && formLessons.length ? formLessons.length : 0 }}
          บท</small
        > -->
      </div>
      <!-- <b-button
        v-if="isLesson"
        size="sm"
        type="submit"
        variant="primary"
        :disabled="disabled"
        @click.prevent="addLesson"
        >เพิ่ม</b-button
      > -->
    </div>
    <div class="col-lg-12 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName"
          >ประเภทการสอน</label
        >
        <div class="d-flex flex-row">
          <v-select
            class="mx-2"
            id="lesson_start_hrs"
            :options="reasonTypes"
            v-model="lessonForm.reasonTypes"
          ></v-select>
        </div>
      </div>
      <br>
    <div class="d-flex flex-row flex-wrap mb-2">
      <div class="col-lg-12 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">ชื่อบทเรียน</label>

        <b-form-input
          id="lastName"
          type="text"
          v-model="lessonForm.lesson_name"
        ></b-form-input>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกชื่อบทเรียน
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-6 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">วันที่เรียน</label>

        <datepicker
          ref="datepicker"
          id="lessonDate"
          input-class="form-control"
          required
          :language="th"
          :format="formatBuddhistBirthDate"
          :disabled="disabled"
          v-model="lessonForm.lesson_start_date"
          @selected="onLessonDateChanged($event)"
        ></datepicker>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกวันที่เรียน
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-6 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="box8">เวลาเรียน</label>
        <div class="d-flex flex-row">
          <v-select
            class="mx-2"
            id="lesson_start_hrs"
            :options="isHrs"
            v-model="lessonForm.lesson_start_time_hr"
            :disabled="disabled"
            style="width: 100px"
            placeholder="ชั่วโมง"
          ></v-select>
          <span class="d-flex flex-column justify-content-around"
            ><b>:</b></span
          >
          <v-select
            class="mx-2"
            id="lesson_start_mins"
            :options="isMins"
            v-model="lessonForm.lesson_start_time_min"
            :disabled="disabled"
            style="width: 100px"
            placeholder="นาที"
          ></v-select>
        </div>
      </div>
      <div class="col-lg-12 col-12 px-2 mb-2" v-if="lessonForm.reasonTypes !== 'สอนสด'">
        <label class="font-weight-bold" for="lastName"
          >Link Video บทเรียน</label
        >
        <b-form-input
          id="lastName"
          type="text"
          v-model="lessonForm.lesson_watch_url"
        ></b-form-input>
        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอก Link Youtube บทเรียน
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-12 col-12 px-2 mb-2" v-if="lessonForm.reasonTypes == 'สอนสด'">
        <label class="font-weight-bold" for="lastName"
          >Link สำหรับการสอนสดผ่าน Zoom</label
        >
        <b-form-input
          id="lastName"
          type="text"
          disabled
          value="https://zoom.us/j/92695451977?pwd=OFNDK21Lckh5bnNiUjVMS1NYYnhBdz09"
        ></b-form-input>
        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอก Link Youtube บทเรียน
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-12 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">รายละเอียดบทเรียน</label>

        <b-textarea name="note" id="note" v-model="lessonForm.lesson_detail">
        </b-textarea>
      </div>
      <div class="col-lg-12 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName"
          >กำหนดระยะเวลาการเรียนเพื่อเริ่มทำแบบทดสอบ</label
        >
        <div class="d-flex flex-row">
          <v-select
            class="mx-2"
            id="lesson_start_hrs"
            :options="isCheckTime"
            v-model="lessonForm.isCheckTime"
          ></v-select>
        </div>
        <br />
        <div class="d-flex flex-row">
          <v-select
            class="mx-2"
            id="lesson_start_hrs"
            :options="isHrs"
            v-model="lessonForm.lesson_time_to_show_test_hr"
            :disabled="lessonForm.isCheckTime != 'กำหนดเวลา'"
            style="width: 100px"
            placeholder="ชั่วโมง"
          ></v-select>
          <span class="d-flex flex-column justify-content-around"
            ><b>ชั่วโมง :</b></span
          >
          <v-select
            class="mx-2"
            id="lesson_start_mins"
            :options="isMins"
            v-model="lessonForm.lesson_time_to_show_test_min"
            :disabled="lessonForm.isCheckTime != 'กำหนดเวลา'"
            style="width: 100px"
            placeholder="นาที"
          ></v-select>
          <span class="d-flex flex-column justify-content-around"
            ><b> นาที</b></span
          >
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between mt-4 my-2 px-2">
      <div class="d-flex flex-row align-items-baseline">
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold ft-s-18">แบบทดสอบ</h4>
        <!-- <small class="text-black-50 ml-2 font-weight-bold"
          >จำนวน
          {{
            formTeachingDare &&
            formTeachingDare.teacher_dares &&
            formTeachingDare.teacher_dares.length
              ? formTeachingDare.teacher_dares.length
              : 0
          }}
          คน</small
        > -->
      </div>
      <b-button size="sm" variant="primary">จัดการแบบทดสอบ</b-button>
    </div>
    <div>
      <div class="d-flex flex-row flex-wrap">
        <div class="col-lg-12 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5"
            >เลือกแบบทดสอบ</label
          >
          <!-- <input class="form-control custom__height" type="text" /> -->
          <v-select
            label="test_name"
            id="id"
            :options="testList"
            v-model="lessonForm.selectedTest"
          ></v-select>
        </div>
        <!-- <div
          class="d-block col-lg-2 col-12 mb-2 pb-1 text-right"
          style="margin: auto"
        >
          <b-button
        size="sm"
        variant="primary"
        @click.prevent="addTeacher"
        ><span style="font-size: 0.8rem;">จัดการแบบทดสอบ</span></b-button
      >
        </div> -->
        <!-- <div
          class="d-block col-lg-1 col-12 mb-2 pb-1 text-right"
          style="margin: auto"
        >
          <font-awesome-icon
            icon="window-close"
            class="fa-lg cursor-pointer"
            @click.prevent="deleteTeacher(index)"
          />
        </div> -->
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between mt-4 my-2 px-2">
      <div class="d-flex flex-row align-items-baseline">
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold ft-s-18">เพิ่มเอกสารประกอบการสอน</h4>
        <!-- <small class="text-black-50 ml-2 font-weight-bold"
          >จำนวน
          {{
            formTeachingDare &&
            formTeachingDare.teacher_dares &&
            formTeachingDare.teacher_dares.length
              ? formTeachingDare.teacher_dares.length
              : 0
          }}
          คน</small
        > -->
      </div>
      <!-- <b-button
        size="sm"
        variant="primary"
        @click.prevent="addTeacher"
        >เพิ่ม</b-button
      > -->
    </div>
    <div>
      <div class="d-flex flex-row flex-wrap">
        <div class="col-12 px-2">
          <label class="font-weight-bold ft-s-14" for="box9"
            >แนบเอกสารประกอบการสอน</label
          >
          <b-button
            size="sm"
            variant="primary"
            class="mx-2"
            :disabled="true"
            @click="toggleAttachFile(index)"
          >
            เลือกไฟล์
          </b-button>
          <!-- <small class="text-black-50 ml-2 font-weight-bold"
              >*JPG, PNG ขนาดไม่เกิน 15MB (ขนาดแนะนำ {{ imageWidth }} x
              {{ imageHeight }} px)</small
            > -->
          <!-- <small class="ml-2 font-weight-bold" style="color: #f83245"
              >*กำลังพัฒนา</small
            > -->
          <input
            v-show="false"
            type="file"
            :id="`lession-file-${index}`"
            name="addLessonPlanImg"
            accept="image/*"
            multiple
            ref="addLessonPlanImg"
            @change="previewImages($event, index)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
// import { quillEditor } from "vue-quill-editor";
import Compressor from "compressorjs";
import { th } from "vuejs-datepicker/dist/locale";
// import CarouselTeachingImage from "../carousel/TeachingImage";
// import Datepicker from "vuejs-datepicker";
import { formatBuddhistBirthDate } from "../../helpers/datepicker-helper";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "axios";

export default {
  components: {
    vSelect,
    // quillEditor,
    // CarouselTeachingImage,
    Datepicker,
  },
  props: {
    data: Object,
    isLesson: {
      type: Boolean,
      default: false,
    },
    isClass: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    imageWidth: {
      type: Number,
      default: 1920,
    },

    imageHeight: {
      type: Number,
      default: 1080,
    },
  },

  model: {
    prop: "data",
    event: "change",
  },

  data() {
    return {
      testList: [],
      lessonName: "",
      isProcessing: false,
      formLessons: this.data,
      lessonForm: {
        lesson_name: "",
        lesson_start_date: "",
        lesson_start_time_hr: "",
        lesson_start_time_min: "",
        lesson_watch_url: "",
        lesson_detail: "",
        lesson_time_to_show_test_hr: "",
        lesson_time_to_show_test_min: "",
        isCheckTime: "กำหนดเวลา",
        testId: null,
        selectedTest: null,
        reasonTypes: 'สอนผ่าน Video'
      },
      // maxFiles: 2,
      // lesson_start_time_hr: this.data
      //   ? this.data.reduce((acc, element) => {
      //       let times = element.time.split("-");
      //       if (times[0]) {
      //         let startTime = times[0].split(":");
      //         acc.push(startTime[0] ? startTime[0] : null);
      //       } else {
      //         acc.push(null);
      //       }
      //       return acc;
      //     }, [])
      //   : [],
      // lesson_start_time_min: this.data
      //   ? this.data.reduce((acc, element) => {
      //       let times = element.time.split("-");
      //       if (times[0]) {
      //         let startTime = times[0].split(":");
      //         acc.push(startTime[1] ? startTime[1] : null);
      //       } else {
      //         acc.push(null);
      //       }
      //       return acc;
      //     }, [])
      //   : [],
      // endHr: this.data
      //   ? this.data.reduce((acc, element) => {
      //       let times = element.time.split("-");
      //       if (times[1]) {
      //         let endTime = times[1].split(":");
      //         acc.push(endTime[0] ? endTime[0] : null);
      //       } else {
      //         acc.push(null);
      //       }
      //       return acc;
      //     }, [])
      //   : [],
      // endMin: this.data
      //   ? this.data.reduce((acc, element) => {
      //       let times = element.time.split("-");
      //       if (times[1]) {
      //         let endTime = times[1].split(":");
      //         acc.push(endTime[1] ? endTime[1] : null);
      //       } else {
      //         acc.push(null);
      //       }
      //       return acc;
      //     }, [])
      //   : [],
      reasonTypes: ["สอนผ่าน Video", "สอนสด"],
      isCheckTime: ["กำหนดเวลา", "ไม่กำหนดเวลา"],
      isHrs: [
        "ไม่กำหนดเวลา",
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      isMins: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      // // editorOption: {
      // //   modules: {
      // //     toolbar: [
      // //       ["bold", "italic", "underline", "strike"],
      // //       ["blockquote", "code-block"],
      // //       [{ header: 1 }, { header: 2 }],
      // //       [{ list: "ordered" }, { list: "bullet" }],
      // //       [{ indent: "-1" }, { indent: "+1" }],
      // //       [{ direction: "rtl" }],
      // //       [{ size: ["small", false, "large", "huge"] }],
      // //       [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // //       [{ font: [] }],
      // //       [{ color: [] }, { background: [] }],
      // //       [{ align: [] }],
      // //       ["clean"],
      // //     ],
      // //   },
      // //   placeholder: "รายละเอียด",
      // // },
      th: th,
      // lessonDate: this.data
      //   ? this.data.reduce((acc, element) => {
      //       acc.push(
      //         element.date
      //           ? new Date(element.date).setFullYear(
      //               new Date(element.date).getFullYear() - 543
      //             )
      //           : null
      //       );
      //       return acc;
      //     }, [])
      //   : [],
    };
  },

  watch: {
    lessonForm: {
      deep: true,
      handler: "syncData",
    },

    // lessonDate: {
    //   handler: "onLessonDateChanged",
    // },

    // data: {
    //   immediate: true,
    //   handler: "syncDateData",
    // },
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      var promise = await axios.get(`https://e-learnning-service-temp.yuzudigital.com/tests`);
      console.log(promise.data);
      this.testList = promise.data.data;
      // const promise = await Survey.api().findAll(
      //   {
      //     ...additionalParams,
      //     limit: ctx.perPage,
      //     offset: (ctx.currentPage - 1) * ctx.perPage,
      //   },
      //   { save: false }
      // );
    },
    formatBuddhistBirthDate,

    setTimeLesson(event, index) {
      // if (event == null) {
      //   return (this.formLessons[index].time = "");
      // }
      if (
        this.lesson_start_time_hr[index] == null &&
        this.lesson_start_time_min[index] == null &&
        this.endHr[index] == null &&
        this.endMin[index] == null
      ) {
        this.formLessons[index].time = "";
      } else {
        this.formLessons[
          index
        ].time = `${this.lesson_start_time_hr[index]}:${this.lesson_start_time_min[index]}-${this.endHr[index]}:${this.endMin[index]}`;
      }
    },

    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ":", ...minutes] : hours;
    },
    timeRangeMask(value) {
      const numbers = value.replace(/[^0-9]/g, "");
      if (numbers.length > 4) {
        return [
          ...this.timeMask(numbers.substring(0, 4)),
          "-",
          ...this.timeMask(numbers.substring(4)),
        ];
      }
      return [...this.timeMask(numbers)];
    },

    toggleAttachFile(i) {
      if (this.formLessons[i]) {
        const { teaching_actual_imgs = [] } = this.formLessons[i];

        if (teaching_actual_imgs.length >= this.maxFiles) {
          return this.$toast.error(
            `ไม่สามารถแนบหลักฐานการสอนบทที่ ${i + 1}. ได้เกิน ${
              this.maxFiles
            } รูป`
          );
        }

        this.$nextTick(() => {
          const { addLessonPlanImg } = this.$refs;

          if (addLessonPlanImg[i]) {
            addLessonPlanImg[i].click();
          }
        });
      }
    },

    addLesson() {
      this.formLessons.push({
        lesson_name: "",
        date: "",
        time: "",
        content: "",
        note: "",
        teaching_actual_imgs: [],
      });

      this.$nextTick(() => {
        const latestIndex = this.formLessons.length - 1;
        const latestLesson = this.$refs[`formLesson__${latestIndex}`];

        if (latestLesson && latestLesson.length) {
          latestLesson[0].scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      });
    },

    deleteLesson(index) {
      this.formLessons.splice(index, 1);
    },

    syncData(v) {
      this.$emit("change", v);
    },

    syncDateData(v) {
      if (v.length == 0) return;
      for (let index = 0; index < v.length; index++) {
        const element = v[index].date;
        if (element) {
          this.lessonDate[index] = new Date(element).setFullYear(
            new Date(element).getFullYear() - 543
          );
        }
      }
    },

    async previewImages(event, index) {
      let { maxFiles } = this;

      if (this.formLessons[index]) {
        const { teaching_actual_imgs = [] } = this.formLessons[index];

        if (teaching_actual_imgs.length < this.maxFiles) {
          maxFiles -= teaching_actual_imgs.length;
        }
      }

      if (maxFiles <= 0) {
        return;
      }

      const self = this;

      const { imageWidth, imageHeight } = this;

      var input = event.target;

      const maxAllowedSize = 15 * 1024 * 1024;

      this.isProcessing = true;

      let fileCounter = 0;

      Array.from(input.files)
        .slice(0, maxFiles)
        .forEach((file) => {
          if (file.size <= maxAllowedSize) {
            fileCounter++;

            new Compressor(file, {
              quality: 0.8,

              maxWidth: imageWidth,

              maxHeight: imageHeight,

              // The compression process is asynchronous,
              // which means you have to access the `result` in the `success` hook function.
              success(result) {
                fileCounter -= 1;

                var reader = new FileReader();

                reader.onload = async (e) => {
                  self.formLessons[index].teaching_actual_imgs.push({
                    file: result,
                    raw: e.target.result,
                  });

                  if (fileCounter === 0) {
                    self.isProcessing = false;
                  }
                };

                reader.readAsDataURL(result);
              },
              error(err) {
                console.log(err.message);
                this.$toast.error(
                  "ไม่สามารถอัพโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง"
                );
              },
            });

            // var reader = new FileReader();

            // reader.onload = async (e) => {
            //   this.formLessons[index].teaching_actual_imgs.push({
            //     file,
            //     raw: e.target.result,
            //   });
            // };

            // reader.readAsDataURL(file);
          } else {
            this.$toast.error(
              "ไม่สามารถอัพโหลดไฟล์ขนาดใหญ่เกิน 15 MB ได้ กรุณาลองใหม่อีกครั้ง"
            );
          }
        });

      if (fileCounter === 0) {
        this.isProcessing = false;
      }

      input.value = "";

      // if (
      //   input.files &&
      //   input.files[0] &&
      //   input.files[0].size > maxAllowedSize
      // ) {
      //   input.value = "";
      // } else {
      //   var reader = new FileReader();
      //   reader.onload = async (e) => {
      //     this.formLessons[index].teaching_actual_imgs.push(e.target.result);
      //   };
      //   reader.readAsDataURL(input.files[0]);
      // }
    },

    totalTime(time) {
      if (time.length !== 11) return "--ชั่วโมง --นาที";

      if (time) {
        let lessonTime = time.split("-");

        let [h1, m1] = lessonTime[0].split(":");
        let [h2, m2] = lessonTime[1].split(":");

        let totalHr;
        let totalMin;
        let totalHrs;
        let totalMins;

        if (h2 >= h1) {
          totalHr = parseInt(h2) - parseInt(h1);
        } else {
          totalHr = 24 + parseInt(h2) - parseInt(h1);
        }

        if (m2 >= m1) {
          totalMin = parseInt(m2) - parseInt(m1);
        } else {
          totalMin = 60 + parseInt(m2) - parseInt(m1);
          totalHr = totalHr - 1;
        }

        // if (totalHr < 10) {
        //   totalHrs = "0" + totalHr.toString();
        // } else {
        //   totalHrs = totalHr.toString();
        // }

        // if (totalMin < 10) {
        //   totalMins = "0" + totalMin.toString();
        // } else {
        //   totalMins = totalMin.toString();
        // }

        totalHrs = totalHr.toString();
        totalMins = totalMin.toString();

        return `${totalHrs.toString()}ชั่วโมง ${totalMins.toString()}นาที`;
      }
    },

    onLessonDateChanged(date) {
      if (date) {
        const dObj = new Date(date.getTime());
        dObj.setFullYear(dObj.getFullYear() + 543);
        dObj.setHours(0);
        dObj.setMinutes(0);
        dObj.setSeconds(0);
        this.$set(
          this.lessonForm.lesson_start_date,
          "date",
          dObj.toISOString()
        );
      }
    },
  },
};
</script>

<style lang="scss">
.content-editor {
  &.is-class {
    .ql-toolbar {
      display: none;
    }

    .ql-container {
      border-top: 1px solid #ccc !important;
    }
  }
}
</style>
