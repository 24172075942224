<template>
  <b-modal
    id="create-or-update-lesson-plan-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">ข้อมูลบทเรียน</h3>
      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด:
        {{ $_formatDateMixin_formatShort(editData.updatedAt) }}</span
      >
    </template>

    <!-- <div class="d-flex flex-row align-items-baseline px-2">
      <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
      <h4 class="font-weight-bold ft-s-18">รูปภาพหน้าปกบทเรียน</h4>
    </div>

    <div class="d-flex flex-row my-3">
      <div class="col-12 px-2">
        <label class="font-weight-bold ft-s-14" for="bannerImg"
          >อัพโหลดรูปภาพหน้าปกบทเรียน</label
        >

        <b-button
          size="sm"
          variant="primary"
          class="mx-2"
          :disabled="true"
        >
          เลือกไฟล์
        </b-button>

        <small class="text-black-50 ml-2 font-weight-bold"
          >*JPG, PNG ขนาดไม่เกิน 10MB </small
        >

        <input
          v-show="false"
          type="file"
          id="bannerImg"
          name="bannerImg"
          accept="image/*"
          ref="bannerImg"
        />
      </div>
    </div> -->

   

    <lesson-form
      v-model="lessonForm"
      :isLesson="true"
      :disabled="isSubmiting"
    ></lesson-form>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        :disabled="isSubmiting"
        @click.prevent="saveLessonPlan()"
      >
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import lessonMixin from "../../mixins/lessonMixin";
import validatorMixin from "../../mixins/validatorMixin";
// import TeachingDareForm from "../form/TeachingDare";
import LessonForm from "../form/Lesson";
// import FilterMaster from "../form/FilterMaster";
// import FilterProvince from "../form/FilterProvince";
import { Lesson } from "../../models/Lesson";
import { Auth } from "../../models";

export default {
  mixins: [formatDateMixin, lessonMixin, validatorMixin],

  name: "CreateOrUpdateLessonPlan",

  components: {
    // TeachingDareForm,
    LessonForm,
    // FilterMaster,
    // FilterProvince,
  },

  props: {
    show: Boolean,
    lessonPlanData: Object,
    editData: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      isSubmiting: false,
      filterMaster: {
        mHeadquarterId: this.editData.mHeadquarterId || null,
        mDivisionId: this.editData.mDivisionId || null,
        mStationId: this.editData.mStationId || null,
        // mSchoolId: this.editData.mSchoolId || null,
      },
      filterProvince: {
        mProvinceId: this.editData.mProvinceId || null,
        mAmphurId: this.editData.mAmphurId || null,
        mSchoolId: this.editData.mSchoolId || null,
      },
      formTeachingDare: {},
      formLesson: this.$_lessonMixin_makeLesson(),
      lessonForm: {},
      testList: []
    };
  },

  watch: {
    "editData.teaching_plans": "updateFormLesson",
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },

    authUser() {
      return Auth.user();
    },
  },

  

  methods: {
  
    updateFormLesson(teachingPlans) {
      if (teachingPlans && teachingPlans.length) {
        this.formLesson = this.$_lessonMixin_makeLesson([...teachingPlans]);
      }
    },

    async saveLessonPlan() {
      // const { mStationId, mClassId } = this.filterMaster,
      //   { mSchoolId } = this.filterProvince;

      // const { actual_student, fiscal_year, plan_student, room, term, year } =
      //   this.formTeachingDare;

      // if (
      //   !mStationId ||
      //   !mSchoolId ||
      //   !mClassId ||
      //   !fiscal_year ||
      //   !plan_student ||
      //   !room ||
      //   !term
      // ) {
      //   return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      // }
      // else if (
      //   this.formLesson.length > 0 &&
      //   this.$_validatorMixin_checkNullArr(this.formLesson, [
      //     "lesson_name",
      //     "date",
      //     "time",
      //     "content",
      //   ])
      // ) {
      //   return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      // }

      // if (this.formLesson.length > 0) {
      //   let checkTime;
      //   for (let index = 0; index < this.formLesson.length; index++) {
      //     const element = this.formLesson[index].time;
      //     if (
      //       this.$_validatorMixin_checkDateTime(element) === "format_incorrect"
      //     ) {
      //       this.$toast.error(`[ บทที่${index + 1}. ] ฟอร์แมตเวลาไม่ถูกต้อง`);
      //       checkTime = false;
      //       break;
      //     } else if (
      //       this.$_validatorMixin_checkDateTime(element) === "equal_time"
      //     ) {
      //       this.$toast.error(
      //         `[ บทที่${index + 1}. ] เวลาเริ่มต้นและเวลาสิ้นสุด ต้องไม่เท่ากัน`
      //       );
      //       checkTime = false;
      //       break;
      //     } else if (
      //       this.$_validatorMixin_checkDateTime(element) === "wrong_time"
      //     ) {
      //       this.$toast.error(
      //         `[ บทที่${index + 1}. ] เวลาเริ่มต้นต้องน้อยกว่าเวลาสิ้นสุด`
      //       );
      //       checkTime = false;
      //       break;
      //     }
      //     checkTime = true;
      //   }
      //   if (!checkTime) return;
      // }

      // for (let index = 0; index < this.formLesson.length; index++) {
      //   this.formLesson[index].lesson_number = `${index + 1}`;
      // }

      // const body = {
      //   actual_student: actual_student ? actual_student : 0,
      //   plan_student: plan_student ? plan_student : 0,
      //   room,
      //   term,
      //   mStationId,
      //   mSchoolId,
      //   mClassId,
      //   fiscal_year: fiscal_year.toString(),
      //   year: year.toString(),
      //   teaching_plans: this.formLesson,
      // };

      let promise;

      this.isSubmiting = true;

      if(this.lessonForm.selectedTest){
      this.lessonForm.testId = this.lessonForm.selectedTest.id

      }

      if(this.lessonForm.isCheckTime == 'ไม่กำหนดเวลา'){
        this.lessonForm.lesson_time_to_show_test_hr = null;
        this.lessonForm.lesson_time_to_show_test_min = null;
      }


      console.log(this.lessonForm)

      if (this.editData && this.editData.id) {
        promise = await Lesson.api().update(this.editData.id, this.lessonForm);
      } else {
        promise = await Lesson.api().create({
          ...this.lessonForm,
          userProfileId: Auth.user().id,
        });
      }

      this.isSubmiting = false;

      const { data } = promise.response;

      if (data && data.response_status == "SUCCESS") {
        this.$toast.success(
          `${
            this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
          }ข้อมูลแผนการสอนเรียบร้อยแล้ว`
        );
        this.$bvModal.hide("create-or-update-lesson-plan-modal");
        this.$emit("create:success");
      } else {
        if (data.message) {
          this.$toast.error(data.message);
        } else {
          this.$toast.error(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลแผนการสอนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          );
        }
      }
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.formTeachingDare = {};

      this.formLesson = this.$_lessonMixin_makeLesson();

      this.$emit("update:editData", {});
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: 1px solid #d1d2db;
  border-radius: 0.29rem;
}
.modal__header {
  border-bottom: none !important;
}
.modal__footer {
  border-top: none !important;
}
.modal__body {
  overflow-x: hidden !important;
}
.card__header {
  padding-bottom: 0;
}
</style>
