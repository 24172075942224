<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="10"
      >
        <template #cell(isPlan)="{ item }">
          <div
            :class="
              item.isPlan && item.isPlan === 'สำเร็จ'
                ? 'text-success'
                : 'text-danger'
            "
          >
            {{ item.isPlan }}
          </div>
        </template>

        <template #cell(trasactionNumber)="{ item }">
          <div :class="item.hasTransaction ? 'text-success' : ''">
            {{ item.trasactionNumber }}
          </div>
        </template>

        <template #cell(seeDetail)="{ item }">
          <div v-if="seeDetail" class="text-center">
            <b-spinner
              v-if="printingItemId && printingItemId == item.id"
              small
              variant="primary"
              label="printing..."
            ></b-spinner>

            <a v-else href="javascript:void(0)" @click="onSeeDetail(item)">
              <font-awesome-icon icon="file-alt" />
            </a>

            <generate-certificate
              v-if="isReport && printItem && printItem.id === item.id"
              :ref="`pdf_${item.id}`"
              :item="printItem"
              :format="format"
              :type-select="typePrint"
              @onProgressDone="onProgressDone"
            ></generate-certificate>

            <select-type-print-certificate
              v-if="isReport && printItem && printItem.id === item.id"
              :item="printItem"
              @selected:type="onTypeSelected"
              @onHidden="onProgressDone"
            ></select-type-print-certificate>
          </div>
        </template>

        <!-- <template #cell(view)="{ item }">
          <div class="text-center">
           <a  @click="onSeeDetail(item)">
              <font-awesome-icon icon="file-alt" />
            </a>
          </div>
        </template> -->

        <template #cell(edit)="{ item }">
          <div class="text-center">
            <b-button size="sm" @click="editCourse(item)">{{
              actionButtonText
            }}</b-button>
          </div>
        </template>

        <template #cell(delete)="{ item }">
          <div class="text-center">
           <b-button variant="danger" size="sm" @click="deleteTeaching(item)"
              >ลบ</b-button
            >
          </div>
        </template>

        <!-- <template #cell(refresh)="{ item }">
          <div v-if="showDelete" class="text-center">
            <b-button variant="danger" size="sm" @click="deleteTeaching(item)"
              >ลบ</b-button
            >
          </div>
        </template> -->
      </base-table>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import { Auth, TeachingDare } from "../../models";
import BaseTable from "./Base";
import GenerateCertificate from "../report/GenerateCertificate";
import SelectTypePrintCertificate from "../modal/SelectTypePrintCertificate";
import axios from "axios";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "แก้ไข",
    },
    actionLabel: {
      type: String,
      default: "เพิ่ม/แก้ไขข้อมูล",
    },
    seeDetail: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    isReport: {
      type: Boolean,
      default: false,
    },
    survey: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    BaseTable,
    GenerateCertificate,
    SelectTypePrintCertificate,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
      format: "",
      sortBy: "",
      sortDesc: false,
      printItem: null,
      printingItemId: null,
      typePrint: null,
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    onProgressDone() {
      this.printingItemId = null;
    },

    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    async fetch() {
      let data = [];
        // additionalParams = {},
        let numberOfPages = 1;

      this.isFetching = true;

     

      try {
         var promise = await axios.get(
        `https://e-learnning-service-temp.yuzudigital.com/courses`
      );
      console.log(promise.data);
        // const promise = await Survey.api().findAll(
        //   {
        //     ...additionalParams,
        //     limit: ctx.perPage,
        //     offset: (ctx.currentPage - 1) * ctx.perPage,
        //   },
        //   { save: false }
        // );

        let {
          data: responseData = [],
          number_of_pages = 1,
        } = promise.data;

        data = responseData.map((record) => {
          return {
            ...record,
            updated_at: record.updatedAt
              ? this.$_formatDateMixin_formatShort(record.updatedAt)
              : "-",
            created_at: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
          };
        });

        numberOfPages = number_of_pages;
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลแบบประเมินได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      this.numberOfPages = numberOfPages;

      return data;
    },

    async onSeeDetail(item) {
      this.$emit("seeDetail", item);

      this.printingItemId = item.id;

      if (this.isReport) {
        // console.log(item);
        const promise = await TeachingDare.api().findOne(
          item.id,
          {},
          { save: false }
        );

        if (promise) {
          const { data = {} } = promise.response.data;

          this.printItem = { ...data };

          this.$nextTick(() => {
            this.$bvModal.show(
              `select-type-create-certificate-modal_${item.id}`
            );
            // if (this.$refs[`pdf_${item.id}`]) {
            //   this.$nextTick(() => {
            //     const { $refs } = this.$refs[`pdf_${item.id}`];

            //     $refs.html2Pdf.generatePdf();
            //   });
            // }
          });
        } else {
          this.$toast.error(
            "ไม่สามารถดึงข้อมูลแผนการสอนได้ กรุณาลองใหม่อีกครั้ง"
          );
        }
      }
    },

    onTypeSelected(typePrint) {
      this.typePrint = typePrint;

      this.$nextTick(() => {
        if (this.$refs[`pdf_${this.printItem.id}`]) {
          this.$nextTick(() => {
            const { $refs } = this.$refs[`pdf_${this.printItem.id}`];

            $refs.html2Pdf.generatePdf();
          });
        }
      });
    },

    editCourse(item) {
      this.$emit("edit", item);
    },

    seeQrCode(item) {
      this.$emit("seeQrCode", item);
    },

    deleteTeaching(item) {
      this.$emit("delete", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
