<template>
  <div class="lesson__container">
    <div class="d-flex flex-row justify-content-between my-3 px-2">
      <div class="d-flex flex-row align-items-baseline">
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold ft-s-18">รายละเอียดหลักสูตร</h4>
        <!-- <small class="text-black-50 ml-2 font-weight-bold"
          >จำนวน
          {{ formLessons && formLessons.length ? formLessons.length : 0 }}
          บท</small
        > -->
      </div>
      <!-- <b-button
        v-if="isLesson"
        size="sm"
        type="submit"
        variant="primary"
        :disabled="disabled"
        @click.prevent="addLesson"
        >เพิ่ม</b-button
      > -->
    </div>
    <div class="d-flex flex-row flex-wrap mb-2">
      <div class="col-lg-6 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName"
          >เปิดให้ลงทะเบียนวันที่</label
        >

        <datepicker
          ref="datepicker"
          id="lessonDate"
          input-class="form-control"
          required
          :language="th"
          :format="formatBuddhistBirthDate"
          :disabled="disabled"
          v-model="courseForm.course_register_start_date"
          @selected="onCourseStartDateChanged($event)"
        ></datepicker>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกชื่อหลักสูตร
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-6 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName"
          >สิ้นสุดการลงทะเบียนวันที่</label
        >

        <datepicker
          ref="datepicker"
          id="lessonDate"
          input-class="form-control"
          required
          :language="th"
          :format="formatBuddhistBirthDate"
          :disabled="disabled"
          v-model="courseForm.course_register_end_date"
          @selected="onCourseStartDateChanged($event)"
        ></datepicker>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกชื่อโรงเรียน
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-8 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">ชื่อหลักสูตร</label>

        <b-form-input
          id="lastName"
          type="text"
          v-model="courseForm.course_name"
        ></b-form-input>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกชื่อหลักสูตร
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">สถานะ</label>

        <v-select
          class="mx-2"
          id="lesson_start_hrs"
          :options="statuses"
          v-model="courseForm.course_status"
        ></v-select>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกชื่อโรงเรียน
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-8 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">อาจารย์ผู้สอน</label>

        <b-form-input
          id="lastName"
          type="text"
          v-model="courseForm.course_teacher_name"
        ></b-form-input>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกชื่ออาจารย์ผู้สอน
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">รหัสวิชา</label>

        <b-form-input
          id="lastName"
          type="text"
          v-model="courseForm.course_code"
        ></b-form-input>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกรหัสวิชา
        </b-form-invalid-feedback>
      </div>
      <div class="col-lg-12 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName"
          >รายละเอียดหลักสูตร</label
        >

        <b-textarea name="note" id="note" v-model="courseForm.course_detail">
        </b-textarea>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกรหัสวิชา
        </b-form-invalid-feedback>
      </div>
      <!-- <div class="col-lg-8 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">กำหนดการณ์รับลงทะเบียนเรียน</label>

        <v-select
              class="mx-2"
              id="lesson_start_hrs"
            ></v-select>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกชื่ออาจารย์ผู้สอน
        </b-form-invalid-feedback>
      </div> -->
      <div class="col-lg-12 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName"
          >หลักสูตรสำหรับชั้นเรียน</label
        >

        <v-select
          class="mx-2"
          id="lesson_start_hrs"
          :options="classes"
          v-model="courseForm.course_register_class_condition"
        ></v-select>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกรหัสวิชา
        </b-form-invalid-feedback>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between mt-4 my-2 px-2">
      <div class="d-flex flex-row align-items-baseline">
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold ft-s-18">บทเรียน</h4>
        <!-- <small class="text-black-50 ml-2 font-weight-bold"
          >จำนวน
          {{
            formTeachingDare &&
            formTeachingDare.teacher_dares &&
            formTeachingDare.teacher_dares.length
              ? formTeachingDare.teacher_dares.length
              : 0
          }}
          คน</small
        > -->
      </div>
      <div class="d-flex flex-row align-items-baseline">
        <b-button
          size="sm"
          variant="primary"
          @click.prevent="addTeacher"
          style="margin-right: 10px;"
          >จัดการบทเรียน</b-button
        >

        <b-button size="sm" variant="primary" @click.prevent="addLesson"
          >เพิ่ม</b-button
        >
      </div>
    </div>
    <div>
      <div
        class="d-flex flex-row flex-wrap"
        v-for="(lesson, index) in courseForm.lessonList"
        :key="`lesson-${index}`"
      >
        <div class="col-lg-11 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5"
            >เลือกบทเรียน</label
          >
          <!-- <input class="form-control custom__height" type="text" /> -->
          <v-select
            v-model="courseForm.lessonList[index]"
            label="lesson_name"
            :options="lessonList"
          ></v-select>
        </div>
        <div
          class="d-block col-lg-1 col-12 mb-2 pb-1 text-right"
          style="margin: auto"
        >
          <font-awesome-icon
            icon="window-close"
            class="fa-lg cursor-pointer"
            @click.prevent="deleteLesson(index)"
          />
        </div>
      </div>
    </div>
    <!-- <div class="d-flex flex-row justify-content-between mt-4 my-2 px-2">
      <div class="d-flex flex-row align-items-baseline">
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold ft-s-18">ข้อสอบ</h4>
      </div>
      <div class="d-flex flex-row align-items-baseline">
        <b-button
          size="sm"
          variant="primary"
          @click.prevent="addTeacher"
          style="margin-right: 10px;"
          >จัดการข้อสอบ</b-button
        >

        <b-button size="sm" variant="primary" @click.prevent="addTest"
          >เพิ่ม</b-button
        >
      </div>
    </div> -->
    <!-- <div>
      <div class="d-flex flex-row flex-wrap" v-for="(test, index) in courseForm.testList" :key="`test_${index}`" :ref="`test_${index}`">
        <div class="col-lg-11 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5">เลือกข้อสอบ</label>
          <v-select label="test_name" :options="testList" v-model="courseForm.testList[index]"></v-select>
        </div>
        <div
          class="d-block col-lg-1 col-12 mb-2 pb-1 text-right"
          style="margin: auto"
        >
          <font-awesome-icon
            icon="window-close"
            class="fa-lg cursor-pointer"
            @click.prevent="deleteTest(index)"
          />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
// import { quillEditor } from "vue-quill-editor";
import Compressor from "compressorjs";
import { th } from "vuejs-datepicker/dist/locale";
// import CarouselTeachingImage from "../carousel/TeachingImage";
// import Datepicker from "vuejs-datepicker";
import { formatBuddhistBirthDate } from "../../helpers/datepicker-helper";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import { Lesson } from "../../models/Lesson";
import axios from "axios";

export default {
  components: {
    vSelect,
    // quillEditor,
    // CarouselTeachingImage,
    Datepicker,
  },
  props: {
    data: Array,
    lessonData: Array,
    isLesson: {
      type: Boolean,
      default: false,
    },
    isClass: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    imageWidth: {
      type: Number,
      default: 1920,
    },

    imageHeight: {
      type: Number,
      default: 1080,
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },

  model: {
    prop: "data",
    event: "change",
  },

  data() {
    return {
      courseForm: {
        course_name: this.editData.course_name || "",
        course_code: this.editData.course_code || "",
        course_register_start_date:
          this.editData.course_register_start_date || "",
        course_register_end_date: this.editData.course_register_end_date || "",
        course_register_class_condition:
          this.editData.course_register_class_condition || "ทั้งหมด",
        course_status: this.editData.course_status || "รอเปิดสอน",
        course_teacher_name: this.editData.course_teacher_name || "",
        course_detail: this.editData.course_detail || "",
        lessonList: [],
        testList: [],
      },
      classes: ["ทั้งหมด", "ป.1 - ป.6", "ม.1 - ม.6"],
      statuses: ["รอเปิดสอน", "เปิดสอน"],
      isProcessing: false,
      formLessons: [],
      maxFiles: 2,
      // startHr: this.data
      //   ? this.data.reduce((acc, element) => {
      //       let times = element.time.split("-");
      //       if (times[0]) {
      //         let startTime = times[0].split(":");
      //         acc.push(startTime[0] ? startTime[0] : null);
      //       } else {
      //         acc.push(null);
      //       }
      //       return acc;
      //     }, [])
      //   : [],
      // startMin: this.data
      //   ? this.data.reduce((acc, element) => {
      //       let times = element.time.split("-");
      //       if (times[0]) {
      //         let startTime = times[0].split(":");
      //         acc.push(startTime[1] ? startTime[1] : null);
      //       } else {
      //         acc.push(null);
      //       }
      //       return acc;
      //     }, [])
      //   : [],
      // endHr: this.data
      //   ? this.data.reduce((acc, element) => {
      //       let times = element.time.split("-");
      //       if (times[1]) {
      //         let endTime = times[1].split(":");
      //         acc.push(endTime[0] ? endTime[0] : null);
      //       } else {
      //         acc.push(null);
      //       }
      //       return acc;
      //     }, [])
      //   : [],
      // endMin: this.data
      //   ? this.data.reduce((acc, element) => {
      //       let times = element.time.split("-");
      //       if (times[1]) {
      //         let endTime = times[1].split(":");
      //         acc.push(endTime[1] ? endTime[1] : null);
      //       } else {
      //         acc.push(null);
      //       }
      //       return acc;
      //     }, [])
      //   : [],
      isHrs: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      isMins: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      // editorOption: {
      //   modules: {
      //     toolbar: [
      //       ["bold", "italic", "underline", "strike"],
      //       ["blockquote", "code-block"],
      //       [{ header: 1 }, { header: 2 }],
      //       [{ list: "ordered" }, { list: "bullet" }],
      //       [{ indent: "-1" }, { indent: "+1" }],
      //       [{ direction: "rtl" }],
      //       [{ size: ["small", false, "large", "huge"] }],
      //       [{ header: [1, 2, 3, 4, 5, 6, false] }],
      //       [{ font: [] }],
      //       [{ color: [] }, { background: [] }],
      //       [{ align: [] }],
      //       ["clean"],
      //     ],
      //   },
      //   placeholder: "รายละเอียด",
      // },
      th: th,
      lessons: [],
      // lessonDate: this.data
      //   ? this.data.reduce((acc, element) => {
      //       acc.push(
      //         element.date
      //           ? new Date(element.date).setFullYear(
      //               new Date(element.date).getFullYear() - 543
      //             )
      //           : null
      //       );
      //       return acc;
      //     }, [])
      //   : [],
      testList: [],
      lessonList: [],
    };
  },

  watch: {
    courseForm: {
      deep: true,
      handler: "syncData",
    },

    // lessonDate: {
    //   handler: "onLessonDateChanged",
    // },

    // data: {
    //   immediate: true,
    //   handler: "syncDateData",
    // },
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      console.log(this.editData);
      if (this.editData.id) {
        var courseData = await axios.get(
          `https://e-learnning-service-temp.yuzudigital.com/courses/${this.editData.id}`
        );
        console.log(courseData.data);
        for (
          let index = 0;
          index < courseData.data.data.lessons.length;
          index++
        ) {
          const element = courseData.data.data.lessons[index];
          this.courseForm.lessonList.push({
            lesson_name: element.lesson.lesson_name,
            id: element.lessonId,
          });
        }
      }

      console.log(this.courseForm)

      // this.testList = promise.data.data;

      var promise = await axios.get(`https://e-learnning-service-temp.yuzudigital.com/tests`);
      console.log(promise.data);
      this.testList = promise.data.data;

      var promiseLesson = await axios.get(`https://e-learnning-service-temp.yuzudigital.com/lessons`);
      console.log('get lesson' + promiseLesson.data);
      this.lessonList = promiseLesson.data.data;

      // this.courseForm.lessonList.push({
      //   lesson_name: "",
      //   id: ""
      // })
      // const promise = await Survey.api().findAll(
      //   {
      //     ...additionalParams,
      //     limit: ctx.perPage,
      //     offset: (ctx.currentPage - 1) * ctx.perPage,
      //   },
      //   { save: false }
      // );
    },
    formatBuddhistBirthDate,
    // addLesson(){
    //   this.courseForm.lessonList.push([])
    // },
    async onClickLesson() {
      const promise = await Lesson.api().findAll();
      this.lessons = promise.response.data.data;
      console.log(this.lessons);
    },
    setTimeLesson(event, index) {
      // if (event == null) {
      //   return (this.formLessons[index].time = "");
      // }
      if (
        this.startHr[index] == null &&
        this.startMin[index] == null &&
        this.endHr[index] == null &&
        this.endMin[index] == null
      ) {
        this.formLessons[index].time = "";
      } else {
        this.formLessons[
          index
        ].time = `${this.startHr[index]}:${this.startMin[index]}-${this.endHr[index]}:${this.endMin[index]}`;
      }
    },

    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ":", ...minutes] : hours;
    },
    timeRangeMask(value) {
      const numbers = value.replace(/[^0-9]/g, "");
      if (numbers.length > 4) {
        return [
          ...this.timeMask(numbers.substring(0, 4)),
          "-",
          ...this.timeMask(numbers.substring(4)),
        ];
      }
      return [...this.timeMask(numbers)];
    },

    toggleAttachFile(i) {
      if (this.formLessons[i]) {
        const { teaching_actual_imgs = [] } = this.formLessons[i];

        if (teaching_actual_imgs.length >= this.maxFiles) {
          return this.$toast.error(
            `ไม่สามารถแนบหลักฐานการสอนบทที่ ${i + 1}. ได้เกิน ${
              this.maxFiles
            } รูป`
          );
        }

        this.$nextTick(() => {
          const { addLessonPlanImg } = this.$refs;

          if (addLessonPlanImg[i]) {
            addLessonPlanImg[i].click();
          }
        });
      }
    },

    addLesson() {
      this.courseForm.lessonList.push({
        lesson_name: "",
        id: "",
      });

      // this.$nextTick(() => {
      //   const latestIndex = this.formLessons.length - 1;
      //   const latestLesson = this.$refs[`formLesson__${latestIndex}`];

      //   if (latestLesson && latestLesson.length) {
      //     latestLesson[0].scrollIntoView({
      //       behavior: "smooth",
      //       block: "end",
      //       inline: "nearest",
      //     });
      //   }
      // });
    },

    deleteLesson(index) {
      this.courseForm.lessonList.splice(index, 1);
    },

    addTest() {
      this.courseForm.testList.push({
        test_name: "",
        id: "",
      });

      this.$nextTick(() => {
        const latestIndex = this.courseForm.testList.length - 1;
        const latestLesson = this.$refs[`test_${latestIndex}`];

        if (latestLesson && latestLesson.length) {
          latestLesson[0].scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      });
    },

    deleteTest(index) {
      this.courseForm.testList.splice(index, 1);
    },

    syncData(v) {
      this.$emit("change", v);
    },

    syncDateData(v) {
      if (v.length == 0) return;
      for (let index = 0; index < v.length; index++) {
        const element = v[index].date;
        if (element) {
          this.lessonDate[index] = new Date(element).setFullYear(
            new Date(element).getFullYear() - 543
          );
        }
      }
    },

    async previewImages(event, index) {
      let { maxFiles } = this;

      if (this.formLessons[index]) {
        const { teaching_actual_imgs = [] } = this.formLessons[index];

        if (teaching_actual_imgs.length < this.maxFiles) {
          maxFiles -= teaching_actual_imgs.length;
        }
      }

      if (maxFiles <= 0) {
        return;
      }

      const self = this;

      const { imageWidth, imageHeight } = this;

      var input = event.target;

      const maxAllowedSize = 15 * 1024 * 1024;

      this.isProcessing = true;

      let fileCounter = 0;

      Array.from(input.files)
        .slice(0, maxFiles)
        .forEach((file) => {
          if (file.size <= maxAllowedSize) {
            fileCounter++;

            new Compressor(file, {
              quality: 0.8,

              maxWidth: imageWidth,

              maxHeight: imageHeight,

              // The compression process is asynchronous,
              // which means you have to access the `result` in the `success` hook function.
              success(result) {
                fileCounter -= 1;

                var reader = new FileReader();

                reader.onload = async (e) => {
                  self.formLessons[index].teaching_actual_imgs.push({
                    file: result,
                    raw: e.target.result,
                  });

                  if (fileCounter === 0) {
                    self.isProcessing = false;
                  }
                };

                reader.readAsDataURL(result);
              },
              error(err) {
                console.log(err.message);
                this.$toast.error(
                  "ไม่สามารถอัพโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง"
                );
              },
            });

            // var reader = new FileReader();

            // reader.onload = async (e) => {
            //   this.formLessons[index].teaching_actual_imgs.push({
            //     file,
            //     raw: e.target.result,
            //   });
            // };

            // reader.readAsDataURL(file);
          } else {
            this.$toast.error(
              "ไม่สามารถอัพโหลดไฟล์ขนาดใหญ่เกิน 15 MB ได้ กรุณาลองใหม่อีกครั้ง"
            );
          }
        });

      if (fileCounter === 0) {
        this.isProcessing = false;
      }

      input.value = "";

      // if (
      //   input.files &&
      //   input.files[0] &&
      //   input.files[0].size > maxAllowedSize
      // ) {
      //   input.value = "";
      // } else {
      //   var reader = new FileReader();
      //   reader.onload = async (e) => {
      //     this.formLessons[index].teaching_actual_imgs.push(e.target.result);
      //   };
      //   reader.readAsDataURL(input.files[0]);
      // }
    },

    totalTime(time) {
      if (time.length !== 11) return "--ชั่วโมง --นาที";

      if (time) {
        let lessonTime = time.split("-");

        let [h1, m1] = lessonTime[0].split(":");
        let [h2, m2] = lessonTime[1].split(":");

        let totalHr;
        let totalMin;
        let totalHrs;
        let totalMins;

        if (h2 >= h1) {
          totalHr = parseInt(h2) - parseInt(h1);
        } else {
          totalHr = 24 + parseInt(h2) - parseInt(h1);
        }

        if (m2 >= m1) {
          totalMin = parseInt(m2) - parseInt(m1);
        } else {
          totalMin = 60 + parseInt(m2) - parseInt(m1);
          totalHr = totalHr - 1;
        }

        // if (totalHr < 10) {
        //   totalHrs = "0" + totalHr.toString();
        // } else {
        //   totalHrs = totalHr.toString();
        // }

        // if (totalMin < 10) {
        //   totalMins = "0" + totalMin.toString();
        // } else {
        //   totalMins = totalMin.toString();
        // }

        totalHrs = totalHr.toString();
        totalMins = totalMin.toString();

        return `${totalHrs.toString()}ชั่วโมง ${totalMins.toString()}นาที`;
      }
    },

    onCourseStartDateChanged(date) {
      if (date) {
        const dObj = new Date(date.getTime());
        dObj.setFullYear(dObj.getFullYear() + 543);
        dObj.setHours(0);
        dObj.setMinutes(0);
        dObj.setSeconds(0);
        this.$set(
          this.courseForm.course_register_start_date,
          "date",
          dObj.toISOString()
        );
      }
    },
    onCourseEndDateChanged(date) {
      if (date) {
        const dObj = new Date(date.getTime());
        dObj.setFullYear(dObj.getFullYear() + 543);
        dObj.setHours(0);
        dObj.setMinutes(0);
        dObj.setSeconds(0);
        this.$set(
          this.courseForm.course_register_end_date,
          "date",
          dObj.toISOString()
        );
      }
    },
  },
};
</script>

<style lang="scss">
.content-editor {
  &.is-class {
    .ql-toolbar {
      display: none;
    }

    .ql-container {
      border-top: 1px solid #ccc !important;
    }
  }
}
</style>
