<template>
  <b-modal
    id="create-or-update-lesson-plan-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">ข้อมูลหลักสูตร</h3>
      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด:
        {{ $_formatDateMixin_formatShort(editData.updatedAt) }}</span
      >
    </template>

    <div class="d-flex flex-row align-items-baseline px-2">
      <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
      <h4 class="font-weight-bold ft-s-18">รูปภาพหน้าปกหลักสูตร</h4>
    </div>

    <div class="d-flex flex-row my-3">
      <div class="col-12 px-2">
        <label class="font-weight-bold ft-s-14" for="bannerImg"
          >อัพโหลดรูปภาพหน้าปกหลักสูตร</label
        >

        <b-button
          size="sm"
          variant="primary"
          class="mx-2"
          @click="toggleAttachFile"
        >
          เลือกไฟล์
        </b-button>

        <small class="text-black-50 ml-2 font-weight-bold"
          >*JPG, PNG ขนาดไม่เกิน 10MB (ขนาดแนะนำ 400 x 300 px)</small
        >

        <!-- <small
          v-if="
            (invalid &&
              validation.form.file &&
              !validation.form.file.required) ||
              (invalid &&
                validation.form.keyImg &&
                !validation.form.keyImg.required)
          "
          class="text-danger"
        >
          กรุณาเลือกไฟล์
        </small> -->

        <input
          v-show="false"
          type="file"
          id="bannerImg"
          name="bannerImg"
          accept="image/*"
          ref="bannerImg"
          @change="previewImage"
        />
      </div>
    </div>
    <b-row v-if="courseForm.course_header_img || editData.course_header_img">
      <b-col lg="12" class="mb-3">
        <b-card>
          <!-- <a
          href="javascript:void(0)"
          class="btn-remove-banner"
          @click="remove"
        >
          <font-awesome-icon class="mx-auto" icon="window-close" />
        </a>
        <div
          class="img-fluid cursor-pointer"
        ></div> -->

          <b-img
            v-if="courseForm.course_header_img || editData.course_header_img"
            fluid
            :height="150"
            :src="courseForm.course_header_img || editData.course_header_img"
          ></b-img>
        </b-card>
      </b-col>
    </b-row>

    <b-spinner
      v-show="isProcessing"
      label="กำลังอัพโหลดรูปภาพ..."
      class="ml-2 mb-4"
    >
    </b-spinner>

    <course-form
      v-model="courseForm"
      :edit-data="editData"
      :isLesson="true"
      :disabled="isSubmiting"
    ></course-form>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        :disabled="isSubmiting"
        @click.prevent="saveCourse()"
      >
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึกหลักสูตร</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import lessonMixin from "../../mixins/lessonMixin";
import validatorMixin from "../../mixins/validatorMixin";
// import TeachingDareForm from "../form/TeachingDare";
import CourseForm from "../form/Course";
// import FilterMaster from "../form/FilterMaster";
// import FilterProvince from "../form/FilterProvince";
// import { TeachingDare } from "../../models/TeachingDare";
import { Auth } from "../../models";
import axios from "axios";

import Compressor from "compressorjs";

// import CarouselBanner from "../carousel/BannerImage";

export default {
  mixins: [formatDateMixin, lessonMixin, validatorMixin],

  name: "CreateOrUpdateLessonPlan",

  components: {
    // TeachingDareForm,
    CourseForm,
    // CarouselBanner
    // FilterMaster,
    // FilterProvince,
  },

  props: {
    show: Boolean,
    lessonPlanData: Object,
    editData: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      isSubmiting: false,
      filterMaster: {
        mHeadquarterId: this.editData.mHeadquarterId || null,
        mDivisionId: this.editData.mDivisionId || null,
        mStationId: this.editData.mStationId || null,
        // mSchoolId: this.editData.mSchoolId || null,
      },
      filterProvince: {
        mProvinceId: this.editData.mProvinceId || null,
        mAmphurId: this.editData.mAmphurId || null,
        mSchoolId: this.editData.mSchoolId || null,
      },
      formTeachingDare: {},
      formLesson: this.$_lessonMixin_makeLesson(),
      courseForm: {
        course_name: "",
        course_code: "",
        course_register_start_date: "",
        course_register_end_date: "",
        course_register_class_condition: "",
        course_status: "",
        course_teacher_name: "",
        course_header_img: this.editData.course_header_img || "",
        course_detail: "",
        lessonList: [],
        id: this.editData.id || null,
      },
      isProcessing: false,
      disabled: {
        type: Boolean,
        default: false,
      },
    };
  },

  watch: {
    "editData.teaching_plans": "updateFormLesson",
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },

    authUser() {
      return Auth.user();
    },
  },

  methods: {
    toggleAttachFile() {
      this.$nextTick(() => {
        this.$refs.bannerImg.click();
      });
    },

    async previewImage(event) {
      console.log("selected file");
      const self = this;
      const { width, height } = this;

      var input = event.target;

      const maxAllowedSize = 10 * 1024 * 1024;

      Array.from(input.files).forEach((file) => {
        if (file.size <= maxAllowedSize) {
          this.isProcessing = true;

          new Compressor(file, {
            quality: 0.8,

            width,

            height,

            resize: "cover",

            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success(result) {
              var reader = new FileReader();

              reader.onload = async () => {
                let formData = new FormData();
                formData.append("uploadFile", result);
                console.log("formData", formData);
                this.uploaded = true;
                const response = await axios.post(
                  `https://e-learnning-service-temp.yuzudigital.com/files/formData`,
                  formData
                );
                // .then(function () {
                console.log("SUCCESS!!");
                console.log("response", response.data);

                // self.$set(self.courseForm, "file", result);
                // self.$set(self.courseForm, "raw", e.target.result);
                self.$set(
                  self.courseForm,
                  "course_header_img",
                  response.data.data.path
                );
                self.courseForm.course_header_img = response.data.data.path;

                self.isProcessing = false;
              };

              reader.readAsDataURL(result);
            },
            error(err) {
              console.log(err.message);
              this.$toast.error("ไม่สามารถอัพโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง");
            },
          });
        } else {
          this.$toast.error(
            "ไม่สามารถอัพโหลดไฟล์ขนาดใหญ่เกิน 10 MB ได้ กรุณาลองใหม่อีกครั้ง"
          );
        }
      });

      console.log(this.courseForm);

      input.value = "";
    },
    updateFormLesson(teachingPlans) {
      if (teachingPlans && teachingPlans.length) {
        this.formLesson = this.$_lessonMixin_makeLesson([...teachingPlans]);
      }
    },

    async saveCourse() {
      // const { mStationId, mClassId } = this.filterMaster,
      //   { mSchoolId } = this.filterProvince;

      // const { actual_student, fiscal_year, plan_student, room, term, year } =
      //   this.formTeachingDare;

      // if (
      //   !mStationId ||
      //   !mSchoolId ||
      //   !mClassId ||
      //   !fiscal_year ||
      //   !plan_student ||
      //   !room ||
      //   !term
      // ) {
      //   return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      // }
      // else if (
      //   this.formLesson.length > 0 &&
      //   this.$_validatorMixin_checkNullArr(this.formLesson, [
      //     "lesson_name",
      //     "date",
      //     "time",
      //     "content",
      //   ])
      // ) {
      //   return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      // }

      console.log(this.courseForm);

      // if (this.formLesson.length > 0) {
      //   let checkTime;
      //   for (let index = 0; index < this.formLesson.length; index++) {
      //     const element = this.formLesson[index].time;
      //     if (
      //       this.$_validatorMixin_checkDateTime(element) === "format_incorrect"
      //     ) {
      //       this.$toast.error(`[ บทที่${index + 1}. ] ฟอร์แมตเวลาไม่ถูกต้อง`);
      //       checkTime = false;
      //       break;
      //     } else if (
      //       this.$_validatorMixin_checkDateTime(element) === "equal_time"
      //     ) {
      //       this.$toast.error(
      //         `[ บทที่${index + 1}. ] เวลาเริ่มต้นและเวลาสิ้นสุด ต้องไม่เท่ากัน`
      //       );
      //       checkTime = false;
      //       break;
      //     } else if (
      //       this.$_validatorMixin_checkDateTime(element) === "wrong_time"
      //     ) {
      //       this.$toast.error(
      //         `[ บทที่${index + 1}. ] เวลาเริ่มต้นต้องน้อยกว่าเวลาสิ้นสุด`
      //       );
      //       checkTime = false;
      //       break;
      //     }
      //     checkTime = true;
      //   }
      //   if (!checkTime) return;
      // }

      // for (let index = 0; index < this.formLesson.length; index++) {
      //   this.formLesson[index].lesson_number = `${index + 1}`;
      // }

      try {
        console.log(this.editData);
        var savedData = null;
        if (this.editData && this.editData.id) {
          savedData = await axios.put(
            `https://e-learnning-service-temp.yuzudigital.com/courses/${this.editData.id}`,
            this.courseForm
          );
        } else {
          savedData = await axios.post(
            `https://e-learnning-service-temp.yuzudigital.com/courses/`,
            this.courseForm
          );
        }

        //   var savedData = await axios.post(
        //   `https://e-learnning-service-temp.yuzudigital.com/courses/`,
        //   this.courseForm
        // );
        console.log(savedData.data);

        const { data } = savedData;

        if (data && data.response_status == "SUCCESS") {
          this.$toast.success(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลเรียบร้อยแล้ว`
          );

          this.$bvModal.hide("create-or-update-lesson-plan-modal");

          this.$emit("create:success");
        } else {
          this.$toast.error(
            `${data.response_description} กรุณาลองใหม่อีกครั้ง`
          );
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.formTeachingDare = {};

      this.formLesson = this.$_lessonMixin_makeLesson();

      this.$emit("update:editData", {});
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: 1px solid #d1d2db;
  border-radius: 0.29rem;
}
.modal__header {
  border-bottom: none !important;
}
.modal__footer {
  border-top: none !important;
}
.modal__body {
  overflow-x: hidden !important;
}
.card__header {
  padding-bottom: 0;
}
</style>
