<template>
  <b-modal
    :id="`select-type-create-certificate-modal_${item.id}`"
    centered
    no-close-on-backdrop
    title="กรุณาเลือกตำแหน่งผู้ลงนาม"
    @hidden="onHidden"
  >
    <b-form-group class="d-flex flex-row my-0 ml-3">
      <b-form-radio
        v-model="selected"
        name="some-radios"
        value="ผู้กำกับการ"
        class="py-3"
        ><h4 class="font-weight-bold">ผู้กำกับการ</h4>
      </b-form-radio>
      <b-form-radio
        v-model="selected"
        name="some-radios"
        value="ผู้บังคับการ"
        class="py-3"
        ><h4 class="font-weight-bold">ผู้บังคับการ</h4>
      </b-form-radio>
      <b-form-radio
        v-model="selected"
        name="some-radios"
        value="สารวัตร"
        class="py-3"
        ><h4 class="font-weight-bold">สารวัตร</h4>
      </b-form-radio>
      <b-form-radio
        v-model="selected"
        name="some-radios"
        value="สารวัตรใหญ่"
        class="py-3"
        ><h4 class="font-weight-bold">สารวัตรใหญ่</h4>
      </b-form-radio>
    </b-form-group>

    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="dark" @click="cancel()"> ยกเลิก </b-button>

      <b-button size="sm" variant="primary" @click="onTypeSelected">
        <!-- <b-spinner
          v-if="printingItemId && printingItemId == item.id"
          small
          variant="light"
          label="printing..."
        ></b-spinner> -->
        <span>ยืนยัน</span>
      </b-button>
    </template>

    <!-- <generate-certificate
      :ref="`pdf_${item.id}`"
      :item="item"
      :format="format"
      :type-select="selected"
      @onProgressDone="onProgressDone"
    ></generate-certificate> -->
  </b-modal>
</template>

<script>
// import GenerateCertificate from "../report/GenerateCertificate";
import { Auth } from "../../models";

export default {
  // components: {
  //   GenerateCertificate,
  // },
  props: {
    show: Boolean,
    item: Object,
    typePrint: String,
  },

  data() {
    return {
      isSubmiting: false,
      // format: "",
      selected: "",
      // printingItemId: null,
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    onTypeSelected() {
      this.$emit("selected:type", this.selected);
      this.$bvModal.hide(
        `select-type-create-certificate-modal_${this.item.id}`
      );
    },

    // async printCertificate(item) {
    //   // สั่ง print รายละเอียด
    //   if (!this.selected) return this.$toast.error("กรุณาเลือกตำแหน่งผู้ลงนาม");

    //   this.printingItemId = item.id;

    //   const promise = await TeachingDare.api().findOne(
    //     item.id,
    //     {},
    //     { save: false }
    //   );

    //   if (promise) {
    //     const { data = {} } = promise.response.data;

    //     this.printItem = { ...data };

    //     this.$nextTick(() => {
    //       if (this.$refs[`pdf_${item.id}`]) {
    //         this.$nextTick(() => {
    //           const { $refs } = this.$refs[`pdf_${item.id}`];

    //           $refs.html2Pdf.generatePdf();
    //         });
    //       }
    //     });
    //   } else {
    //     this.$toast.error(
    //       "ไม่สามารถดึงข้อมูลแผนการสอนได้ กรุณาลองใหม่อีกครั้ง"
    //     );
    //   }
    // },

    // onProgressDone() {
    //   this.printingItemId = null;
    // },

    onHidden() {
      this.$emit("onHidden");
      this.clearData();
    },

    clearData() {
      this.selected = "";
    },
  },
};
</script>
